import { render, staticRenderFns } from "./Minas-Gerais.vue?vue&type=template&id=6cf969af&scoped=true&"
import script from "./Minas-Gerais.vue?vue&type=script&lang=js&"
export * from "./Minas-Gerais.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf969af",
  null
  
)

export default component.exports